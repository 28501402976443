import React from 'react';
import {
  Added,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="February 2019"
      subnav="release-notes">
      <div id="February2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Is it just us or is February sneaky eventful? Between Valentine’s Day,
          Black History Month and those resolutions you want to revisit
          post-January, there’s a lot going on. And while we’d love to say the
          same about Uniform, it would be a stretch.
        </Paragraph>
        <Paragraph>
          The “bigger, relatively more exciting things” mentioned last month are
          still happening, of course. But until they’re really ready, find joy
          in timestamps, selects and a fresh set of cursors.{' '}
          <a className="uni-link uni-link--article" href="/rn-2019-02.gif">
            Weeeeeeee!
          </a>
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Improved>
                <Link href="http://hudl.com/design/writing/grammar/date-time-numbers-addresses#dates/">
                  Date and time guidelines
                </Link>{' '}
                to include more practical dos and don’ts.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.27.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.27.0"
            />

            <div className={styles.changesList}>
              <Added>
                Yet another x-small somethin’, this time via{' '}
                <Link href="/components/selects/lookup-multi-select/design">
                  lookup multi-select
                </Link>
                .
              </Added>
              <Added>Support for disabled options in all selects.</Added>
              <Improved>Styling for all selects.</Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>Uniform colors to the global library.</Added>
              <Added>Web cursor types—think Default, Pointer and Grab.</Added>
              <Added>
                Our friend{' '}
                <Link href="/components/notifications/note/design/">
                  the note
                </Link>{' '}
                (for dark environment).
              </Added>
              <Improved>Styling for all selects.</Improved>
              <Improved>
                Overrides just to make things a little tidier.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
